import React from "react";

import { Link } from "gatsby";
import Image from "../common/utils/Image";
import Cta from "components/common/Cta";
import "./styles.sass";
import { pathologiesDaughterSlug, pathologiesGrandMotherSlug, pathologiesMotherSlug } from "templates/Pathologies/utils/slugs";
import useAirtableCategories from 'hooks/useAirtableCategories';
import T from "types/index";
import { AirtableImages } from "components/common/utils/AirtableImage";
import { chunk } from "utils/lodashExtracts";

const MedicalSpecialties = () => {
  const categoriesRecords: T.Charles.CategoryRecord[] = useAirtableCategories()
  return (
    <div className="landing-medical-specialties">
      <h2 className="landing-medical-specialties-h2">
        <Link to={pathologiesGrandMotherSlug()}>Nos spécialités médicales</Link>
      </h2>
      <div className="landing-medical-specialties-subtitle">
        Traitez vos problèmes depuis chez vous grâce à nos protocoles complets
      </div>
      <div className="landing-medical-specialties-section-wrapper">
        {categoriesRecords.map((categoryRecord, index) => (
          <div
            className="landing-medical-specialties-section"
            key={`landing-medical-${index}`}
          >
            <div className="landing-medical-specialties-image">
              <div
                className="landing-medical-specialties-anchor"
                id={`${categoryRecord.data.slug}-anchor`}
              />
              <AirtableImages
                className="landing-medical-specialties-section-box d-md-none d-xl-block"
                images={categoryRecord.data.home_image}
              />
              <AirtableImages
                className="landing-medical-specialties-section-box d-none d-md-block d-xl-none"
                images={categoryRecord.data.home_image_md}
              />
            </div>
            <div className="landing-medical-specialties-text">
              <h3><Link to={pathologiesMotherSlug(categoryRecord.data.slug)}>{categoryRecord.data.home_title}</Link></h3>
              <div className="specialties-section-subtitle">
                {categoryRecord.data.home_description}
              </div>
              <div className="specialties-section-pathologies-wrapper-container d-md-flex flex-row justify-content-between">
                {chunk(categoryRecord.data.home_pathologies, Math.ceil(categoryRecord.data.home_pathologies.length / 2)).map((pathologiesRecords: T.Charles.PathologyRecord[]) => (
                  <div className="specialties-section-pathologies-wrapper">
                    {pathologiesRecords.map((pathologyRecord, subIndex) => (
                      <Link
                        to={pathologiesDaughterSlug(categoryRecord.data.slug, pathologyRecord.data.slug)}
                        key={`landing-medical-pathologies-${subIndex}`}
                        className="specialties-section-pathologies-div"
                      >
                        <h4 className="h4">{pathologyRecord.data.breadcrumb_label}</h4>
                        <Image
                          filename="new-images/arrow-link.svg"
                          alt="fleche-droite-fine-picto"
                          title="fleche droite fine picto"
                        />
                      </Link>
                    ))}
                  </div>
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
      <Cta
        className="new-primary-cta d-xl-none"
        text="Consultez un médecin"
        path="/consulter/"
      />
      <div className="landing-medical-specialties-hr" />
    </div>
  );
};

export default MedicalSpecialties;
